import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EventIcon from "@mui/icons-material/Event";
import Typography from "@mui/material/Typography";

const EventItem = ({ event }) => {
    return (
        <List>
            <ListItem>
                <ListItemText
                    primary={<Typography variant="h6">{event.name}</Typography>}
                />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <LocationOnIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={event.description} />
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <EventIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary={new Date(event.eventDate).toLocaleDateString()} />
            </ListItem>
        </List>
    );
};

export default EventItem;
